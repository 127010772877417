<template>
  <div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <b-icon icon="table" custom-size="default" />
          Analysis '{{ analysis.user_name }}'
        </p>
        <div  style="padding-top:8px; margin-right:10px;">
          <b-button rounded
            size="is-small"
            class="is-primary button-shadow"
            icon-left="eye"
            @click="displayAnalysis()"
          >
          More
          </b-button>
        </div>
      </header>
      <div class="card-content">
        <div class='columns'>
          <div class="column">
              <b>Function : {{analysis.meta.func_name}}</b><br/>
              <b>Parameters: </b><br/>
              <ul class="prettylist">
                <li v-for="(value, key) in analysis.meta.func_args_for_print"
                v-bind:prop="value"
                v-bind:key="key"> <i>{{ key }}</i>: {{ value }}</li>
              </ul>
          </div>
          <div class="column">
            <template v-if="png">
              <png v-if="png" :data=png />
            </template>
            <template v-if="table">
              <basic-table v-if="table" :data="table"/>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/apiService'
export default {
  name: 'AnalysisView',
  props: {
    analysis: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      table: null,
      png: null
    }
  },
  components: {
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'png': () => import('@/components/rresults/PNG.vue')
  },
  mounted () {
    if (this.analysis !== null) {
      this.refreshPreview()
    }
  },
  watch: {
    analysis: function () {
      this.refreshPreview()
    }
  },
  methods: {
    displayAnalysis: function () {
      this.$router.push({
        name: 'workflow',
        params: {
          'name': this.analysis.object_name }
      })
    },
    refreshPreview: function () {
      apiService.runRFunction({
        'func_name': 'r_preview_analysis',
        'nameAnalysis': this.analysis.object_name
      })
        .then(data => {
          this.png = null
          this.table = null
          if (data.plotfile) {
            this.png = data.plotfile
          }
          if (data.Table) {
            this.table = data.Table
          }
        })
        .catch(error => {
          this.error = error.data.error
        })
    }
  }
}
</script>
